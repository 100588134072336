body {
  background: $white;
  line-height: 1.4;
  font-size: $fz;
  min-width: 320px;
  font-family: 'montserrat', Arial, sans-serif;
  color: $c-text;
  @include no-scroll;
  @include sm {
    font-size: $fz-sm; } }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  &.is-loaded:not(.no-animation) {
    animation: fadeIn 1s; } }

.container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

.main {
  &--no-color {
    .section:nth-child(odd) {
      background-color: $white; } } }

.logo {
  display: inline-block;
  font-size: 0;
  @include sm {
    max-width: 80px; } }

.tooltip {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  left: 0; }

.price {
  strong {
    color: $accent;
    font-size: 1.6em; } }

.link {
  font-weight: 700;
  @include after {
    border-top: 1px solid currentColor;
    width: 50px;
    display: inline-block;
    margin-left: 15px;
    position: relative;
    top: -.2em;
    @include sm {
      margin-left: 10px; } } }

.no-touch {
  .no-scroll {
    .side-links {
      right: 17px; } } }

.side-links {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 80px;
  z-index: 10;
  @include flex(center, flex-end, column);
  &__link {
    display: block;
    margin-bottom: 10px;
    @include md {
      margin-bottom: 5px; }
    @include xs {
      margin-bottom: 0; }
    .icon {
      font-size: 65px;
      @include md {
        font-size: 45px; }
      @include xs {
        font-size: 35px; } } } }

.number {
  color: $accent;
  font-size: 24px;
  position: relative;
  border: 1px solid  $c-text;
  border-radius: 50%;
  @include flex(center, center);
  width: 50px;
  height: 50px;
  @include xs {
    width: 30px;
    height: 30px;
    font-size: 18px; }
  @include before {
    background-color: $white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 1;
    width: 20px;
    height: 10px; } }

.amount {
  @include inline-flex(flex-start, center);
  .price {
    margin-left: 10px; } }

