.footer {
  background-color: $black-light;
  color: $gray-100;
  &__inner {
    @include flex(flex-start, normal, row, wrap);
    padding-top: 45px;
    padding-bottom: 45px;
    @include lg {
      padding-top: 30px;
      padding-bottom: 30px; }
    @include md {
      padding-top: 20px;
      padding-bottom: 20px; }
    @include sm {
      padding-top: 15px;
      padding-bottom: 15px; } }
  &__logo {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__text {
    padding-bottom: 15px;
    font-size: $fz-xs;
    p {
      margin-bottom: 15px; }
    @include md {
      margin-bottom: 10px;
      p {
        margin-bottom: 15px; } } }
  &__auth {
    color: $white;
    a {
      @include tr(color .3s);
      @include hover {
        color: $accent; } } }
  &__title {
    margin-bottom: 30px;
    @include lg {
      margin-bottom: 20px; }
    @include md {
      margin-bottom: 15px; }
    @include sm {
      margin-bottom: 10px; } }
  &__left {
    width: percentage(3/12);
    padding-right: 15px;
    @include md {
      width: 100%;
      margin-bottom: 20px;
 } }      // order: 1
  &__right {
    width: percentage(9/12);
    padding-left: 15px;
    @include flex(flex-start, normal, row, wrap);
    @include md {
      padding-left: 0;
      width: 100%;
 } }      // order: 0
  &__block {
    // &:nth-child(1)
    // &:nth-child(2)
    // &:nth-child(3)
    flex: 0 0 240px;
    @include lg {
      flex: 0 0 33.33333%; }
    @include xs {
      flex: 0 0 50%; }
    &:last-child {
      flex-grow: 1;
      @include lg {
        flex-grow: initial; }
      @include xs {
        flex: 0 0 100%; } } } }
