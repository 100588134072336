$fz:    15px;
$fz-sm: 13px;
$fz-xs: 12px;

$c-text: #6b6b6b;

$black: #000;
$black-light: #202020;

$white: #fff;
$white-dark: #f9f9f9;
$white-darken: #aeaeae;

$gray: #9d9d9d;
$gray-dark: #353535;
$gray-100: #7c7c7c;
$gray-light: #eeeeee;

$accent: #da0000;
$blue: #2873c6;
$yellow: #f8e83c;

$header-height: 120px;
$header-height-lg: 89px;
$header-height-sm: 60px;
