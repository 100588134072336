.types {}

.type-link {
  $this: &;
  // +flex(flex-start, center, column)
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  background-color: $white;
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  @include xs {
    margin-bottom: 15px;
    height: calc(100% - 15px); }
  @include hover {
    #{$this}__btn {
      opacity: 1;
      visibility: visible; } }
  &.is-disabled {
    .title {
      opacity: .1; } }
  &__content {
    // +flex(flex-start, center, column)
    // flex-grow: 1
    display: block;
    width: 100%;
    padding-top: 35px;
 }    // padding-bottom: 15px
  &__title {
    display: block;
    margin-bottom: 10px; }
  &__price {
    display: block;
    margin-left: auto;
    max-width: 200px;
    width: 100%;
    // min-width: 155px
    background-color: $white-dark;
    padding: 10px;
    text-align: center;
    @include xs {
      padding: 10px; } }
  &__img {
    display: block;
    flex-grow: 1;
    position: relative;
    padding-top: 35%;
    width: 100%;
    // background-repeat: no-repeat
    // background-position: 50% 50%
    // background-size: contain
    @include xs {
      padding-top: 30%; }
    img {
      @include vcenter;
      max-height: 100%; } }
  &__btn {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    @include tr(opacity .3s, visibility .3s); } }

.type-top {
  &__title {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; }
    @include sm {
      margin-bottom: 5px; } } }
