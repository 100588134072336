.popup {
  $this: &;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($black, .7);
  padding: 15px;
  overflow: auto;
  @include vertical;
  visibility: hidden;
  opacity: 0;
  @include ready {
    @include tr(opacity .3s, visibility .3s); }
  &.is-active {
    visibility: visible;
    opacity: 1; }
  &__inner {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    font-size: $fz;
    position: relative;
    background-color: $white;
    width: 100%;
    max-width: 930px;
    @include sm {
      font-size: $fz-sm; } }
  &__close {
    @include icon-close(20, 1, currentColor);
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 15px;
    @include tr(color .3s);
    @include hover {
      color: $accent; } }
  &__top, &__bottom {
    background-color: $white-dark;
    padding: 30px;
    text-align: center;
    @include lg {
      padding: 20px; }
    @include sm {
      padding: 15px; } }
  &__top {
    margin-bottom: 60px;
    @include xl {
      margin-bottom: 40px; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &__amount {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } }
  &__btn {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } }

  &--sm {
    #{$this}__inner {
      max-width: 450px; } } }

