small {
  font-size: inherit; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

.title {
  display: block;
  font-weight: 700;
  line-height: 1;
  @include css-lock(21, 40, 320, 1399, font-size);
  color: $gray-dark;

  &--h3 {
     @include css-lock(18, 24, 320, 1399, font-size); }
  &--h5 {
    @include css-lock(13, 15, 767, 1399, font-size);
    @include r(320) {
      font-size: $fz-sm !important; } }
  &--white {
    color: $white; }
  &--u {
    text-decoration: underline; }
  &--yellow {
    color: $yellow; } }
