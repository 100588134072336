.no-touch {
  .no-scroll {
    .header {
      right: 17px; } } }

.header {
  $this: &;
  position: fixed;
  z-index: 15;
  left: 0;
  right: 0;
  top: 0;
  background-color: transparent;
  color: $white;
  @include tr(background-color .3s);
  &.is-scrolled {
    background-color: rgba($black, .7);
    #{$this}__inner {
      padding-top: 10px;
      padding-bottom: 10px;
      height: $header-height-sm; }
    #{$this}__logo {
      width: 80px; } }
  &__inner {
    @include flex(flex-start, center);
    height: $header-height;
    padding-top: 25px;
    padding-bottom: 25px;
    @include tr(height .3s, padding .3s);
    @include lg {
      padding-top: 15px;
      padding-bottom: 15px;
      height: $header-height-lg; }
    @include sm {
      padding-top: 10px;
      padding-bottom: 10px;
      height: $header-height-sm; } }
  &__logo {
    margin-right: 60px;
    @include xll {
      margin-right: 40px; }
    @include xl {
      margin-left: 0px; }
    @include lg {
      flex-grow: 1; } }
  &__nav {
    flex-grow: 1;
    @include lg {
      position: fixed;
      z-index: 15;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 15px;
      background-color: $black-light;
      top: 0;
      left: 0;
      @include vertical;
      // opacity: 0
      // visibility: hidden
      transform: translate(-100%, 0);
      @include ready {
        @include tr(opacity .3s, visibility .3s, transform .3s); }
      &.is-active {
        transform: translate(0, 0);
        // opacity: 1
 } } }        // visibility: visible

  &__nav-in {
    @include lg {
      display: inline-block;
      vertical-align: middle;
      font-size: $fz;
      @include sm {
        font-size: $fz-sm; } } }
  &__phone {
    margin-left: 60px;
    padding-top: 4px;
    @include xll {
      margin-left: 40px; }
    @include xl {
      margin-left: 0px; } }
  &__burger {
    display: none;
    margin-left: 15px;
    @include lg {
      display: block; }
    @include xs {
      margin-left: 10px; } }
  &__close {
    @include icon-close(20, 1, currentColor);
    display: none;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    @include lg {
      display: block; } } }




.header-phone {
  @include inline-flex(flex-start, center);
  font-weight: 700;
  &__icon {
    display: block;
    margin-right: 10px;
    background-color: $accent;
    border-radius: 50%;
    width: 2.3em;
    height: 2.3em;
    flex: 0 0 2.3em;
    position: relative;
    .icon {
      font-size: 21px;
      @include vcenter; }
    @include xss {
      margin-right: 0; } }
  &__title {
    display: block;
    border-top: 1px solid $gray-dark;
    border-bottom: 1px solid $gray-dark;
    padding-top: 5px;
    padding-bottom: 5px;
    @include xss {
      display: none; } } }

.burger {
  display: block;
  width: 28px;
  height: 28px;
  // margin-top: -24px
  position: relative;
  span:after, span:before {
    content: "";
    position: absolute;
    left: 0; }
  span:after {
    top: 5px; }
  span:before {
    bottom: 5px; }
  span {
    // position: relative
    display: block; }
  span, span:after, span:before {
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; }
  // &.is-active span
  //   background-color: transparent
  // &.is-active span:before
  //   transform: rotate(45deg) translate(-1px, 0px)
  // &.is-active span:after
 }  //   transform: rotate(-45deg) translate(6px, -7px)
