.form {
  background-color: $white;
  padding: 55px 40px 40px;
  color: $c-text;
  @include xl {
    padding: 40px; }
  @include lg {
    padding: 30px; }
  @include md {
    padding: 20px; }
  &__title, &__subttl {
    text-align: center; }
  &__title {
    margin-bottom: 5px; }
  &__title-mb-lg {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } }
  &__subttl {
    margin-bottom: 40px;
    @include xl {
      margin-bottom: 30px; }
    @include lg {
      margin-bottom: 20px; }
    @include md {
      margin-bottom: 15px; } }
  &__field {
    @include notlast {
      margin-bottom: 35px;
      @include lg {
        margin-bottom: 20px; }
      @include md {
        margin-bottom: 10px; } } }
  &__block {
    max-width: percentage(720/930);
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @include md {
      max-width: 90%; }
    @include sm {
      max-width: none; }
    @include notlast {
      margin-bottom: 30px;
      @include md {
        margin-bottom: 15px; } } }
  &__slider {
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    @include md {
      margin-bottom: 15px; } }


  &--popup {
    padding: 0;
    @include xl {
      padding: 0; }
    @include lg {
      padding: 0; }
    @include md {
      padding: 0; }
    @include sm; } }

.input, .textarea {
  font-size: $fz-xs;
  input, textarea {
    width: 100%;
    border: 0;
    border-bottom: 1px solid  $gray;
    height: 32px;
    padding: 5px 0;
    @include tr(border-color .3s);
    @include placeholder {
      color: inherit; }
    &:focus {
      border-color: $accent; } } }

.attach {
  position: relative;
  cursor: pointer;
  text-align: left;
  text-decoration: underline;
  display: inline-block;
  @include tr(color .3s);
  @include hover {
    color: $accent; }
  input[type="file"] {
    position: absolute;
    visibility: hidden;
    opacity: 0; }
  .icon {
    position: relative;
    font-size: 1.2em;
    top: .2em;
    margin-right: 5px; } }

.check-item {
  text-align: center;
  &__title {} }
