.number-els {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;


  position: relative;
  &__img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 40%;
    width: 65%;
    padding-top: 18%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    z-index: 0;
    pointer-events: none;
    @include xs {
      display: none; } }
  &__els {
    position: relative;
    z-index: 1;
    min-height: 305px;
    @include xs {
      min-height: 0;
      margin-left: -5px;
      margin-right: -5px;
      @include flex(space-between, space-between, row, wrap); } } }

.number-el {
  $this: &;
  position: absolute;
  width: 225px;
  @include xs {
    position: relative;
    width: calc(50% - 10px);
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 15px; }
  @include before {
    border-top: 1px solid  $c-text;
    width: 65px;
    position: absolute;
    @include sm {
      display: none; } }
  @include after {
    position: absolute;
    width: 80px;
    border-top: 1px solid  $c-text;
    @include rotate(60);
    @include sm {
      display: none; } }
  &:nth-child(even) {
    #{$this}__nmb, #{$this}__text {
      margin-left: auto;
      @include xs {
 } } }        // margin-left: 0
  &:nth-child(1) {
    top: 0;
    left: 0;
    @include before {
      top: 80px;
      left: calc(100% + 10px); }
    @include after {
      left: calc(100% + 10px + 65px);
      top: 80px;
      transform-origin: top left; } }
  &:nth-child(2) {
    top: 0;
    right: 0;
    @include before {
      top: 80px;
      right: calc(100% + 10px); }
    @include after {
      right: calc(100% + 10px + 65px);
      top: 80px;
      @include rotate(-60);
      transform-origin: top right; } }
  &:nth-child(3) {
    bottom: 0;
    left: 0;
    @include before {
      bottom: 10px;
      left: calc(100% + 10px); }
    @include after {
      bottom: 10px;
      left: calc(100% + 10px + 65px);
      transform-origin: top left;
      @include rotate(-60); } }
  &:nth-child(4) {
    bottom: 0;
    right: 0;
    @include before {
      bottom: 10px;
      right: calc(100% + 10px); }
    @include after {
      bottom: 10px;
      right: calc(100% + 10px + 65px);
      transform-origin: top right; } }
  &__text {
    max-width: 225px; }
  &__nmb {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } } }

