.section {
  @extend .pt-mdl;
  @extend .pb-mdl;
  &:nth-child(odd) {
    background-color: $white-dark; }
  &__title, &__subttl {
    text-align: center; }
  &__title {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__title-mb-lg {
    margin-bottom: 50px;
    @include xl {
      margin-bottom: 40px; }
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &__subttl {
    margin-bottom: 45px;
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
    @include lg {
      margin-bottom: 35px; }
    @include md {
      margin-bottom: 20px; }
    @include sm {
      margin-bottom: 15px; } }
  &__block {
    @include notlast {
      margin-bottom: 80px;
      @include xl {
        margin-bottom: 60px; }
      @include lg {
        margin-bottom: 40px; }
      @include md {
        margin-bottom: 30px; } } } }

.section-text {
  @extend .row;
  text-align: justify;
  p, ul, ol, h2, h3 {
    @include notlast {
      margin-bottom: 25px;
      @include md {
        margin-bottom: 15px; } } }
  ul {
    li {
      &::before {
        content: '-';
        display: inline-block;
        margin-right: 5px; } } }
  &__left, &__right {
    @extend .col-sm-6;
    @include flex(center, normal, column); }
  &__left {
    @include xs {
      margin-bottom: 25px; } } }
