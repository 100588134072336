.project-link {
  @include flex(flex-end, normal, column);
  color: $white;
  height: 100%;
  padding: 50px 35px;
  padding-top: 125%;
  position: relative;
  @include cover;
  background-color: $gray;
  @include xl {
    padding: 40px 35px;
    padding-top: 125%; }
  @include lg {
    padding: 25px;
    height: auto;
    padding-top: 15%;
    margin-bottom: 30px; }
  @include sm {
    padding: 15px;
    padding-top: 30%;
    margin-bottom: 15px;
 }    // padding-top: 125%
  @include xs {
    padding: 10px;
    padding-top: 30%;
    @include before {
      display: block; } }
  @include before {
    @include coverdiv;
    z-index: 0;
    background-color: rgba($black, .5);
    display: none; }
  &__title {
    margin-bottom: 10px;
    display: block;
    @include md {
      margin-bottom: 5px; } }
  &__text {
    margin-bottom: 25px;
    display: block;
    @include notlast {
      @include md {
        margin-bottom: 15px; }
      @include sm {
        margin-bottom: 10px; } } }
  &__content {
    position: relative;
    z-index: 1; } }
