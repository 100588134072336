.checkbox {
  position: relative;
  padding-left: 1.3em;
  font-size: $fz-xs;
  user-select: none;
  color: $gray;
  display: inline-block;
  input[type="checkbox"] {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    &:checked + span {
      &::after {
        content: '';
        @include icon-arrow(.6, .3, .05, $accent, -45, em);
        top: .4em;
        left: .15em;
        position: absolute;
        display: block; } } }
  > span {
    display: block;
    &::before {
      content: '';
      border: 1px solid $gray;
      width: .9em;
      height: .9em;
      top: .2em;
      left: 0;
      background-color: $white;
      position: absolute;
      display: block; } }

  &--lg {
    padding-left: 2.4em;
    cursor: pointer;
    .title {
      margin-bottom: 5px; }
    input[type="checkbox"] {
      &:checked + span {
        &::after {
          top: 50%;
          @include icon-arrow(1.2, 0.5, .2, $accent, -45, em);
          transform: translate(0, -50%) rotate(-45deg); } } }
    > span {
      &::before {
        width: 1.5em;
        height: 1.5em;
        top: 50%;
        transform: translate(0, -50%); } } } }
