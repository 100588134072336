.btn {
  display: inline-block;
  text-align: center;
  min-width: 300px;
  background-color: $accent;
  color: $white;
  border: 1px solid  $accent;
  padding: 1em;
  font-weight: 700;
  @include tr(border-color .3s, color .3s, background-color .3s);
  @include hover {
    background-color: transparent;
    color: $accent; }
  @include md {
    min-width: 250px; }
  @include xs {
    min-width: 0;
    width: 100%; }

  &--white {
    color: $white;
    border-color: $white;
    @include hover {
      color: $accent;
      border-color: $accent; } }
  &--transp {
    background-color: transparent;
    @include hover {
      background-color: currentColor; } }
  &--transp-white {
    background-color: transparent;
    border-color: $white;
    @include hover {
      background-color: $white; } }
  &--full {
    min-width: 0 !important;
    width: 100%; }
  &--no-hover {
    @include hover {
      color: $white;
      background-color: $accent; } }
  &--lg {
    min-width: 375px; } }
