.prices {
  &__item {
    margin-bottom: 5px; } }

.price-item {
  background-color: $white-dark;
  @include flex(space-between, center);
  padding: 10px 30px;
  @include lg {
    padding: 10px 20px; }
  @include md {
    padding: 10px 15px; }
  @include sm {
    padding: 5px 10px; }
  &__title {
    padding-right: 10px; } }
