.contacts {
  &__right {
    padding-left: 45px;
    @include xl {
      padding-left: 0; } }
  &__left {
    @include flex(center, normal, column);
    height: 100%;
    @include sm {
      margin-bottom: 30px; } } }

.contacts-block {
  text-align: center;
  &__block {

    margin-bottom: 35px;
    @include lg {
      margin-bottom: 25px; }
    @include md {
      margin-bottom: 15px; } }
  &__text {
    a {
      @include tr(color .3s);
      @include hover {
        color: $accent; } } }
  &__title {
    margin-bottom: 10px;
    @include md {
      margin-bottom: 5px; } } }

.map {
  position: relative;
  padding-top: 50%;
  background-color: $gray;
  @include md {
    padding-top: 60%; }
  iframe {
    @include coverdiv;
    z-index: 1; } }
