.step {
  text-align: center;
  &__img {
    position: relative;
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; }
    @include before {
      border-radius: 50%;
      background-color: $gray-light;
      @include vcenter;
      z-index: 0;
      width: 1em;
      height: 1em;
      font-size: 210px;
      @include md {
        font-size: 150px; }
      @include sm {
        font-size: 100px; }
      @include xs {
        font-size: 170px; } } }
  &__img-img {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    padding-top: 60%;
    position: relative;
    z-index: 1; }

  &__title {
    margin-bottom: 10px;
    @include md {
      margin-bottom: 5px; } }
  &__text {
    a {
      color: $blue;
      text-decoration: underline; }
    br {
      @include lg {
        display: none; } } } }


.doc-item {
  position: relative;
  padding-left: 40px;
  text-align: center;
  @include xs {
    padding-left: 20px; }
  &__nmb {
    color: $gray-light;
    @include css-lock(36, 84, 320, 1399, font-size);
    font-weight: 700;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    @include flex(flex-start, center); }
  &__img {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    padding-top: 60%;
    margin-bottom: 15px; }
  &__text {
    @include lg {
      display: none; } } }
