.slider {
  &__wrap {
    position: relative; }

  &--check-items {
    &__wrap {
      margin-left: -15px;
      margin-right: -15px;
      @include sm {
        // padding-left: 15px
 }        // padding-right: 15px
      .tns-outer {
        @extend .form__block;
        @include notlast {
          margin-bottom: 0 !important;
          padding-bottom: 30px !important; } }
      .tns-nav {
        position: absolute;
        width: 100%;
        text-align: center;
        left: 0;
        bottom: 0;
        z-index: 1;
        button {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: $gray;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;
          opacity: 1;
          &.tns-nav-active {
            background-color: $gray-dark; } } } } } }

.prev, .next {
  height: 25px;
  width: 60px;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  @include tr(color .3s);
  @include hover {
    color: $accent; }
  @include md {
    display: none; }
  @include before {
    width: 100%;
    border-top: 1px solid currentColor;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    position: absolute; }
  @include after {
    @include icon-arrow(10, 10, 1, currentColor, 45);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);
    left: 0; } }

.next {
  transform: translate(0, -50%) scaleX(-1);
  right: 25px; }
.prev {
  left: 25px; }
