.hero {
  padding-top: $header-height;
  position: relative;
  color: $white;
  @include cover;
  @include lg {
    padding-top: $header-height-lg; }
  @include sm {
    padding-top: $header-height-sm; }
  @include xs {
 }    // padding-bottom: 15px
  &__content {
    @include flex(center, normal, column);
    padding-top: 25px;
    padding-bottom: 25px;
    @include xs {
 }      // margin-bottom: 15px
    html:not(.is-ie) & {
      min-height: calc(100vh - #{$header-height});
      min-height: calc((var(--vh, 1vh) * 100) - #{$header-height});
      @include lg {
        min-height: calc(100vh - #{$header-height-lg});
        min-height: calc((var(--vh, 1vh) * 100) - #{$header-height-lg}); }
      @include sm {
        min-height: calc(100vh - #{$header-height-sm});
        min-height: calc((var(--vh, 1vh) * 100) - #{$header-height-sm}); }
      @include xs {
        min-height: 0; } }
    .is-ie & {
      height: calc(100vh - #{$header-height});
      height: calc((var(--vh, 1vh) * 100) - #{$header-height});
      @include lg {
        height: calc(100vh - #{$header-height-lg});
        height: calc((var(--vh, 1vh) * 100) - #{$header-height-lg}); }
      @include sm {
        height: calc(100vh - #{$header-height-sm});
        height: calc((var(--vh, 1vh) * 100) - #{$header-height-sm}); }
      @include xs {
        height: auto; } } }
  &__right {
    @include flex(center, normal, column);
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    img {
      object-fit: contain; } }
  &__right-bottom {
    padding-top: 0;
    padding-bottom: 0;
    justify-content: flex-end; }
  &__title {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } }
  &__text {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 25px; } }
  &__btns {
    @include flex();
    @include xl {
      display: block; } }
  &__btn {
    @include notlast {
      margin-right: 15px;
      @include xl {
        margin-right: 0;
        margin-bottom: 15px; } } } }
