.nav {
  li {
    display: inline-block;
    margin-left: 25px;
    margin-right: 25px;
    @include xll {
      margin-left: 15px;
      margin-right: 15px; }
    @include xl {
      margin-left: 10px;
      margin-right: 10px; }
    @include lg {
      margin: 0;
      display: block;
      margin-bottom: 15px; }
    &.has-dropdown {
      position: relative;
      > a {
        position: relative;
        display: inline-block;
        @include after {
          @include arr(10, 5, currentColor, b);
          position: relative;
          top: .8em;
          margin-left: 5px;
          @include lg {
            display: none; } } }
      &:hover {
        .tooltip {
          visibility: visible;
          opacity: 1; } } } }

  a {
    @include tr(color .3s);
    @include hover {
      color: $white-darken; }
    &.is-active {
      color: $white-darken; } }

  &--footer {
    li {
      margin: 0;
      display: block;
      margin-bottom: 20px;
      @include xll {
        margin-left: 0;
        margin-right: 0; }
      @include xl {
        margin-left: 0;
        margin-right: 0; }
      @include md {
        margin-bottom: 10px; } }
    a {
      @include hover {
        text-decoration: underline; } }
    .icon {
      position: relative;
      top: .1em;
      margin-right: 10px;
      @include lg {
        margin-right: 5px; }
      @include md {
        margin-right: 3px; } } }
  &--50 {
    ul {
      @include flex(flex-start, normal, row, wrap);
      @include xl {
        display: block; } }
    li {
      width: 50%;
      @include xl {
        width: auto; } }
    img {
      @include notlast {
        margin-right: 10px;
        @include md {
          margin-right: 5px; } } } } }

.nav-tooltip {
  padding-top: 15px;
  font-weight: 500;
  color: $gray-dark;
  width: 465px;
  font-size: $fz-sm;
  @include before {
    @include arr(20, 15, $white, t);
    position: absolute;
    top: 0;
    left: 50px; }
  @include lg {
    position: static;
    visibility: visible;
    opacity: 1;
    padding-top: 0;
    color: inherit;
    @include before {
      display: none; } }
  @include xs {
    width: 100%; }
  ul {
    padding: 25px;
    background-color: $white;
    @include flex(flex-start, normal, row, wrap);
    @include lg {
      background-color: transparent;
      padding: 0;
      padding-top: 10px; } }
  li {
    width: 50%;
    padding-right: 10px;
    margin: 0;
    margin-bottom: 20px;
    @include xll {
      margin-left: 0;
      margin-right: 0; }
    @include xl {
      margin-left: 0;
      margin-right: 0; }
    @include lg {
      padding-right: 5px;
      padding-left: 5px; } }
  a {
    @include hover {
      text-decoration: underline;
      color: $gray; } } }
