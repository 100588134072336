.pt-0 {
  padding-top: 0 !important; }
.pt-xll {
  @include css-lock(30, 200, 320, 1499, padding-top); }
.pt-xl {
  @include css-lock(30, 175, 320, 1499, padding-top); }
.pt-lgl {
  @include css-lock(30, 150, 320, 1499, padding-top); }
.pt-lg {
  @include css-lock(30, 125, 320, 1499, padding-top); }
.pt-mdl {
  @include css-lock(30, 100, 320, 1499, padding-top); }
.pt-md {
  @include css-lock(30, 75, 320, 1499, padding-top); }
.pt-sml {
  @include css-lock(30, 50, 320, 1499, padding-top); }
.pt-sm {
  padding-top: 30px; }

.pb-0 {
  padding-bottom: 0 !important; }
.pb-xll {
  @include css-lock(30, 200, 320, 1499, padding-bottom); }
.pb-xl {
  @include css-lock(30, 175, 320, 1499, padding-bottom); }
.pb-lgl {
  @include css-lock(30, 150, 320, 1499, padding-bottom); }
.pb-lg {
  @include css-lock(30, 125, 320, 1499, padding-bottom); }
.pb-mdl {
  @include css-lock(30, 100, 320, 1499, padding-bottom); }
.pb-md {
  @include css-lock(30, 75, 320, 1499, padding-bottom); }
.pb-sml {
  @include css-lock(30, 50, 320, 1499, padding-bottom); }
.pb-sm {
  padding-bottom: 30px; }
