.feature {
  text-align: center;
  margin-bottom: 50px;
  @include xl {
    margin-bottom: 40px; }
  @include lg {
    margin-bottom: 30px; }
  @include md {
    margin-bottom: 15px; }
  &__icon {
    color: $accent;
    margin-bottom: 25px;
    position: relative;
    border: 1px solid  $c-text;
    border-radius: 50%;
    @include flex(center, center);
    width: 75px;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    @include before {
      background-color: $white-dark;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      z-index: 1;
      width: 20px;
      height: 10px; }
    @include after {
      position: absolute;
      z-index: 2;
      height: 20px;
      border-right: 1px solid  $c-text;
      top: calc(100% - 5px);
      left: 50%;
      transform: translate(-50%, 0); }

    @include md {
      margin-bottom: 15px; }
    .icon {
      font-size: 44px; } }
  &__title {
    font-weight: 700; }
  &__text {
    max-width: 245px;
    margin-left: auto;
    margin-right: auto; } }
